@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    margin: 0;
    padding: 0;
}

.page {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100vw;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    background-color: green;
}

.leftbar {
    width: 15%;
    height: 100%;
    display: flex;
    flex-direction: column;
    color: rgb(107, 106, 106);
    align-items: center;
    background-color: white;
    justify-content: space-between;
}

.rightArea {
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
}

.main {
    width: 95%;
    height: 95%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: center;
}

/* left bar */
.logo-img {
    width: 80%;
}

.left-menu {
    height: 80%;
    width: 80%;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    background-color: #fff;
    color: #333;
    padding: 20px;
}

.left-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.left-menu ul li {
    margin-bottom: 10px;
    /* Adjust the spacing between menu items */
}

.left-menu ul li a {
    color: #333;
    /* Text color for menu items */
    text-decoration: none;
    display: block;
    padding: 5px 0;
    /* Adjust padding as needed */
    transition: color 0.3s;
    /* Smooth color transition on hover */
}

.left-menu ul li a:hover {
    color: #3498db;
    /* Color changes to blue on hover */
}

.left-menu ul li a.active {
    background-color: #3498db;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
}

.logOut {
    text-align: left;
    background-color: white;
    color: inherit;
    padding: 0;
    border: none;
}

.logOut:hover {
    color: #3498db;
    background-color: white;
}

/* chart */
.topbar {
    background-color: #e8e9f5;
    width: 100%;
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 1px 0px grey;
}

.battery {
    border: 1px solid green;
    border-radius: 7px;
    display: flex;
    font-size: 17px;
}

.chart {
    width: 100%;
    /*will squeeze the left bar if higher*/
}

.bottom {
    background-color: green;
    display: flex;
    height: 80%;
}


.timerange {
    display: flex;
}

.timer {
    width: 100%;
    height: 20%;
}

.nav-link {
    color: inherit;
    text-decoration: none;
}

/* alerts */
.alerts-button {
    height: 10%;
}

.alerts {
    height: 80%;
    width: 100%;
    overflow-y: auto;
}

table {
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

thead {
    position: sticky;
    top: 0px;
    overflow: hidden;
}

table th {
    background-color: #3498db;
    color: #ffffff;
    font-weight: bold;
    padding: 10px;
}

table td {
    padding: 5px;
    border: 1px solid #dddddd;
}

table tr:hover {
    background-color: #f5f5f5;
}

/* settings */
.setting {
    width: 30%;
}

.setting select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 10px;
}

.setting input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ffffff;
    margin-bottom: 10px;
}

.setting label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

/* COMMON */
/* button */
button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    background-color: #3498db;
    color: #ffffff;
    border: 2px solid #3498db;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
    position: sticky;
}

button:hover {
    background-color: #2980b9;
    color: #ffffff;
    transform: scale(1.05);
}

button:active {
    background-color: #28a745;
}

/* chart */
input[type="checkbox"] {
    margin-right: 5px;
}

select {
    width: 150px;
    padding: 1px;
    font-size: 16px;
}

/* Style for the datetime-local inputs */
input[type="datetime-local"] {
    width: 250px;
    padding: 1px;
    font-size: 16px;
}

.topbar button {
    font-size: 14px;
    padding: 1px;
    color: white;
    text-decoration: none;
}

/* Style for pagination */
.ReactPaginate {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin-top: 20px;
}

.ReactPaginate li {
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
}

.ReactPaginate a {
    color: #007bff;
    text-decoration: none;
    padding: 8px;
    border: 1px solid #007bff;
    border-radius: 4px;
}

.ReactPaginate a:hover {
    background-color: #007bff;
    color: #fff;
}

.ReactPaginate .break {
    margin: 0 5px;
}

/* Optional: Style for active page */
.ReactPaginate li.selected a {
    background-color: #007bff;
    color: #fff;
}

.list-without-bullet {
    list-style: none;
    padding-left: 0;
}
